
.custom-text-style {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.1px;
  text-align: left;
  color: #FFFFFF;
}

.custom-text-style-initials {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-top :8px;
  letter-spacing: -0.1px;
  text-align: left;
    color: #FFFFFF;
}

.gradient-background-hfk {
  background: linear-gradient(266.69deg, #D509B4 0%, #3CD096 100%);
}

.custom-title-style-hfk {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
  text-align: left;
}

.header-title {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin-left: 20px;
  margin-top: 30px;
  letter-spacing: -0.1px;
  text-align: left;
}

.custom-all-free-features-platinum {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1.5%;
  color: #FFFFFF;
}

.custom-span {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
  text-align: left;
}


.guac-slider-title {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
  letter-spacing: -0.45px; /* Approximate value */
  text-align: left;
}

.custom-title {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32.74px;
  letter-spacing: -0.45px;
  text-align: left;
}


.back-arrow-hfk {
  width: 40px;
  height: 40px;
  top: 49px;
  background-color: #51978c;
  border-radius: 10px;
box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-arrow img {
  width: 32px;
  height: 32px;
}

.--side-image {
  width: 160px;
}

.--side-image > img {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: center center;
}

.__asa_guac_slider:nth-child(2) > .--side-image {
  width: 300px;
}

.__asa_guac_slider:nth-child(3) > .--side-image {
  width: 200px;
}

.--guac-img-gold {
  top: 18px;
  left: 12px;
}

.--guac-img-gold > img {
  width: 80px;
}

.guac-slider-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  letter-spacing: -0.1px;
  text-align: left; /* You can adjust this if needed */
  color: #000000; /* Adjust the color as needed */
}

.custom-box {
  width: 346px;
  height: 131px;
  position: absolute;
  top: 694px;
  left: 20px;
  border-radius: 15px;
  border: 2px solid #E0E0E0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 20px; /* This replaces the p-5 padding */
  margin-top: 24px; /* This replaces the mt-6 margin-top */
}

.custom-box p {
  background-color: white;
  font-size: 12px; /* This replaces text-xs */
  font-weight: 500; /* This replaces font-medium */
  color: #6B7280; /* This replaces text-gray-500 */
}

.custom-paragraph {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  color: #6B7280; /* Assuming this is the desired color */
}

.custom-container {
  height: 131px;
  left: 20px;
  border-radius: 15px;
  border: 2px solid #E0E0E0;
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px; /* Adjust padding if needed */
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust gap between elements if needed */
}
