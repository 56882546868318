.cancel-trial-title {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  letter-spacing: -0.1px;
  margin-bottom: 16px; /* This corresponds to mb-4 in Tailwind */
}
/* CancelTrial.css */
.cancel-text-cancel {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.1px;
  margin-bottom: 8px; /* This corresponds to mb-2 in Tailwind */
}
/* CancelTrial.css */
/* CancelTrial.css */
.continue-access-text-cancel {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.1px;
  opacity: 0.5; /* Set the opacity to 50% */
}
/* CancelTrial.css */
.go-back-button {
  background: #46B601;
  color: white;
  padding: 8px 16px; /* py-2 px-4 equivalent */
  border-radius: 10px;
  font-weight: 600; /* font-semibold */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* CancelTrial.css */
.go-back-button-text {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700; /* Bold */
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.1px;
  text-transform: uppercase;
}

.manage-go {
  border-radius: 10px;
  background-color: #46B601;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 50%;
  color: #FFF;
  padding: 8px 0; /* Equivalent to py-2 */
}

.manage-go-btn:hover {
  background-color: #38A169; /* Change the hover color if needed */
}

.cancel-trial-btn-cancel {
  border-radius: 10px;
  border: 1px solid #FFF;
  background: rgba(70, 182, 1, 0);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 50%;
  color: #FFF;
  padding: 8px 0; /* Equivalent to py-2 */
}

.cancel-trial-btn-cancel:hover {
  background-color: rgba(70, 182, 1, 0.1); /* Hover effect */
}
.cancel-trial-btn-txt-trial {
  border-radius: 10px;
  border: 1px solid #FFF;
  background: rgba(70, 182, 1, 0);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 50%;
  color: #FFF;
  padding: 8px 0; /* Equivalent to py-2 */
}
.manage-trial-btn-cancel {
  border-radius: 10px;
  background-color: #46B601;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 50%;
  color: #FFF;
  padding: 8px 0; /* Equivalent to py-2 */
}




