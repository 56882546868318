.label-expand {
  top: 50%;
  left: 15px;
  font-size: 20rem; /* Adjusted for larger text */
  transform: translateY(-50%) scale(1);
   font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Semi-bold */
}

.label-shrink-address {
  top: -15px; /* Adjust based on the font size */
  left: 7px;
  font-size: 1.50rem; /* Increased size for the shrunk label */
  color: #FFFFFF;
  transform: scale(0.75);
   font-family: 'Poppins', sans-serif;
  font-weight: 600; /* Semi-bold */
}
.label-left {
  left: 15px; /* Adjust as needed */
}
.default-bg {
  background-color: #705AE2;
}

.blur-bg {
  background-color: #2e2750;
}



