.label-expand-port {
  top: 50%;
  left: 15px;
  font-size: 20rem; /* Adjusted for larger text */
  transform: translateY(-50%) scale(1);
   font-family: 'Poppins', sans-serif;
  font-weight: 400; /* Semi-bold */
}

.label-shrink-port {
  top: -15px; /* Adjust based on the font size */
  left: 7px;
  font-size: 1.50rem; /* Increased size for the shrunk label */
  color: #FFFFFF;
  transform: scale(0.75);
   font-family: 'Poppins', sans-serif;
  font-weight: 600; /* Semi-bold */
}
.label-left {
  left: 15px; /* Adjust as needed */
}
.default-bg {
  background-color: #705AE2;
}


.custom-date-picker-port .react-datepicker__input-container input {
  background-color: #121212 !important;
  border-color: #FFFFFF !important;
  color: #FFFFFF !important;
}

.custom-date-picker-port .react-datepicker__triangle {
  display: none;
}


.blur-bg {
  background-color: #2e2750;
}








.button-styles-p-span-port {

  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 13px;
 
  color: #FFFFFF; /* Text color */
  cursor: pointer;
}
/* styles.css */

/* Background Gradient */
.bg-gradient {
    background: linear-gradient(to bottom, #705AE2, #490C95);
}

/* Common Styles */
/* .container {
    max-width: 768px;
    margin: 0 auto;
    padding: 16px;
    background-color: #121212;
    border-radius: 24px 24px 0 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

.header {
    color: #fff;
    text-align: center;
    margin-bottom: 24px;
}

.button {
    background-color: #705AE2;
    color: #fff;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #5635cc;
}

/* Input Fields */
.input-field {
    width: 100%;
    padding: 12px;
    background-color: #121212;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    transition: border-color 0.3s;
}

.input-field:focus {
    border-color: #705AE2;
    outline: none;
}

/* Error Messages */
.error-message {
    color: red;
    font-size: 12px;
    margin-top: -12px;
    margin-bottom: 16px;
}

/* Icons */
.icon {
    width: 32px;
    height: 32px;
}

.selected {
    border: 4px solid #705AE2;
    background-color: #705AE2;
}

/* Additional custom styles as needed */




