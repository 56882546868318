.trial-message-recativate {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.1px;
    opacity: 0.5;
    margin-top: 0.25rem; /* equivalent to mt-1 in Tailwind */

}

.manage-trial-btn-reactivate {
  border-radius: 10px;
  background-color: #46B601;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  color: #FFF;
  padding: 8px 0; /* Equivalent to py-2 */
}

.reactivate-button {
  border-radius: 10px;
  background: #46B601;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  padding: 10px; /* Adjust padding as needed */
  font-size: 14px; /* Adjust font size if necessary */
  font-weight: 600; /* Set font weight */
  text-transform: capitalize; /* Capitalize text */
  width: 100%; /* Ensure full width */
}

.button-text-style-reactivate {
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif; /* Ensure a fallback */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* Adjust as needed */
  letter-spacing: -0.1px;
  text-transform: uppercase;
}



