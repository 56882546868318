.trial-end-text {
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.1px;
  margin-top: 16px; /* equivalent to mt-4 */
}

.trial-date-text {
  color: #FFF;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 12px; /* Same font-size as the first paragraph */
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;
 
}
.plan-text {
  color: #FFF;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 200% */
  opacity: 0.8;
}
.guac-gold-text {
  color: #FFF;
  text-align: right;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
}
.cancel-trial-btn {
  border-radius: 10px;
  border: 1px solid #FFF;
  background: rgba(70, 182, 1, 0);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 50%;
  color: #FFF;
  padding: 8px 0; /* Equivalent to py-2 */
}

.cancel-trial-btn:hover {
  background-color: rgba(70, 182, 1, 0.1); /* Hover effect */
}

.manage-trial-btn {
  border-radius: 10px;
  background-color: #46B601;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 50%;
  color: #FFF;
  padding: 8px 0; /* Equivalent to py-2 */
}

.manage-trial-btn:hover {
  background-color: #38A169; /* Change the hover color if needed */
}

.imgicon {
   
  border-radius: 20px;

}

.cancel-text {

  text-align: right;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  
}
.custom-text-anytime-cancel {
  color: #FFF;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px; /* 200% */
  letter-spacing: -0.1px;
  margin-top: 0.25rem; /* Equivalent to mt-1 in Tailwind */
}
.cancel-trial-btn-txt {
  border-radius: 10px;
  border: 1px solid #FFF;
  background: rgba(70, 182, 1, 0);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 50%;
  color: #FFF;
  padding: 8px 0; /* Equivalent to py-2 */
}

.cancel-trial-btn {
  border-radius: 10px;                          /* Rounded corners */
  border: 1px solid #FFF;                       /* White border */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); /* Shadow effect */
  color: #FFF;                                  /* Text color */
  padding: 10px;                                /* Padding */
  cursor: pointer;                               /* Pointer cursor on hover */
  width: 100%;                                  /* Full width */
  text-align: center;                           /* Center text */
  font-family: 'Poppins', sans-serif;          /* Font family */
  font-size: 14px;                             /* Font size */
  font-style: normal;                           /* Font style */
  font-weight: 700;                            /* Font weight */
  line-height: 20px;                           /* Line height */
  letter-spacing: -0.1px;                      /* Letter spacing */
  text-transform: uppercase;                    /* Uppercase text */
}




