/* Change the color and size of the dots */
.slick-dots li button:before {
  color: gray; /* Change this to your desired color */
  font-size: 10px; /* Reduce this value to make the dots smaller */
margin :8px
}

/* Change the color and size of the active dot */
.slick-dots li.slick-active button:before {
  color: rgb(5, 173, 131); /* Change this to your desired active color */
  font-size: 10px; /* Reduce this value to make the active dot smaller */
}

/* Adjust spacing and styling of the dots container */
.slick-dots {
  background-color: white;
  padding: 5px 0; /* Optional: Adjust vertical padding */
  margin-top: 10px; /* Adjust margin as needed */
  display: flex;
  justify-content: center;
}

/* Reduce size and spacing of individual dots */
.slick-dots li {
  margin: 0 1px; /* Adjust horizontal margin between dots */
}

.slick-dots li button:before {
  width: 8px; /* Reduce this value to make the dots smaller */
  height: 8px; /* Reduce this value to make the dots smaller */
}

/* Additional styling for custom dots */
.dot-custom {
  width: 6px; /* Reduce this value to make the dots smaller */
  height: 6px; /* Reduce this value to make the dots smaller */
  border-radius: 50%;
  background-color: white; /* Change this to your desired color */
}

