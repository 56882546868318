.circle-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem; /* Adjust margin as needed */
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  transition: transform 0.5s ease; /* Smooth transition for zoom-in effect */
}

.circle img {
  width: 110%; /* Adjust image size as needed */
  height: auto;
}

.zoom-in {
  animation: zoomIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94); /* Example of cubic-bezier easing */
}

.move-down {
  transform: translateY(20px); /* Example translation amount, adjust as needed */
}

@keyframes zoomIn {
  from {
    transform: scale(0); /* Start from scale 0 */
  }
  to {
    transform: scale(1); /* End at scale 1 (normal size) */
  }
}

.text-center {
  position: relative;
}
