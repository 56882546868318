.custom-btn-container {
  background-color: #00000033;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 1rem;
  width: 70px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-plan-card {
  background-color: #0b1727;
  margin-top: 2.5rem;
  color: white;
  width: 100%;
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #2f3947;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-plan-cost {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22.4px;
  color: #006bd2;
}

.custom-trial-btn {
  background-color: #22262c;
  color: white;
  padding: 0.75rem 5rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Poppins', sans-serif;
}

.custom-plan-option {
  background-color: #0b1727;
  padding: 1rem 1.5rem;
  height: 60px;
  width: 100%;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #2f3947;
}

.custom-purchase-btn {
  background-color: #006bd2;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem;
  margin-top: 1rem;
  font-weight: 600;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  max-width: 240px;
  width: 100%;
}

.custom-popup-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
  transition: opacity 0.3s ease-in-out;
}

.custom-popup {
  background-color: white;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 320px;
  margin: 0 1rem;
}

.custom-popup-close-btn {
  background-color: #006bd2;
  color: white;
  padding: 0.5rem 1.25rem;
  border-radius:
}

.subscription-title-money {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22.4px;
  color: #006BD2;
  text-align: left;
}

.back-button-container-money {
  background-color: rgba(0, 0, 0, 0.2); /* Darker background */
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5); /* Darker shadow */
  border-radius: 10px; /* Rounded corners */
  padding: 0.25rem; /* Padding */
  width: 70px; /* Width */
  height: 45px; /* Height */
  display: flex; /* Flexbox */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}

.back-button {
  cursor: pointer; /* Pointer cursor */
  width: 20px; /* Image width */
  height: 20px; /* Image height */
}


